import axios from "axios";

import { baseDomain, stage, getAllQuestions, getByIdQuestion, createQuestion, updateQuest, publishQuest, unpublishQuest } from "../../../../Constants/apiRoutes";

export const createSleepSurveyQuestions = (payload) => {
  return axios.post(`${baseDomain}${stage}${createQuestion}`, payload)
}

export const getAllQuestionsApi = () => {
  return axios.get(`${baseDomain}${stage}${getAllQuestions}?isPublished=true`)
}
export const getAllQuestionsApiUnpublish = () => {
  return axios.get(`${baseDomain}${stage}${getAllQuestions}?isPublished=false`)
}
export const getByIdQuestionApi = (questionId) => {
  return axios.get(`${baseDomain}${stage}${getByIdQuestion}/${questionId}`)
}

export const updateQuestions = (payload, questionId) => {
  return axios.put(`${baseDomain}${stage}${updateQuest}?_id=${questionId}`, payload)
}

export const pusblishQuestionsAction = (questionId) => {
  return axios.put(`${baseDomain}${stage}${publishQuest}/${questionId}`)
}

export const UnpublishQuestionAction = (questionId) => {
  return axios.put(`${baseDomain}${stage}${unpublishQuest}/${questionId}`)
}

export const AllUsersData = () => {
  return axios.get(`${baseDomain}${stage}/sleepSurvey/track`)
}
export const AllSurveyData = ({ page, limit, startDate, endDate }) => {
  return axios.get(`${baseDomain}${stage}/sleepSurvey?page=${page}&limit=${limit}&startDate=${startDate}&endDate=${endDate}`)
}
export const AllSurveyDataCSV = ({ startDate, endDate }) => {
  return axios.get(`${baseDomain}${stage}/sleepSurvey?startDate=${startDate}&endDate=${endDate}`)
}