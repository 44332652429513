import { useState, useEffect } from "react";
import EditDiscoverNispandComp from "../../../../../../Components/EditDiscoverNispandComp";
import {useLocation, useParams } from "react-router-dom";
import {Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import {
  getAllMediaApi,
  getAllSeriesPlaylistApi,
  getDiscoverNispandByIdApi,
  updateDiscoverNispandApi,
} from "../../Constants/api";
import SweetAlert from "react-bootstrap-sweetalert";

const EditDiscoverNispand = () => {
  const params = useParams();
  const location = useLocation();
  const idval = params;
  const {editID} = useParams()
  const userRefPath = location.state.userRefPath;
  const [modal, setModal] = useState(false);
  const toggle = () => setModal(!modal);
  const [errMsg, setErrMsg] = useState()
  const [seriesData, setSeriesData] = useState();
  const [mediaData, setMediaData] = useState();
  const [playlistData, setPlaylistData] = useState();
  const [selectedMedia, setSelectedMedia] = useState([]);
  const [selectedPlaylist, setSelectedPlaylist] = useState([]);
  const [selectedSeries, setSelectedSeries] = useState([]);
  const [objType, setObjType] = useState();
  const [currOrder , setCurrOrder] = useState()
  const [order, setOrder] = useState();
  const [mediaCount, setMediaCount] = useState();
  const [seriesCount, setSeriesCount] = useState();
  const [playlistCount, setPlaylistCount] = useState();
  const [showUnPublishAlert, setShowUnPublishAlert] = useState(false);
  const [showUnPublishConfirm, setShowUnPublishConfirm] = useState(false);
  const [formData, setFormData] = useState({
    title: "",
    description: "",
  });

  const { title, description } = formData;

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const getAllMediaData = async (params) => {
    const { data } = await getAllMediaApi(params);
    setMediaData(data?.data);
    setMediaCount(data?.count);
  };

  const getAllSeriesData = async (params) => {
    const { data } = await getAllSeriesPlaylistApi(params);
    setSeriesData(data?.data);
    setSeriesCount(data?.count);
  };
  const getAllPlaylistData = async (params) => {
    const { data } = await getAllSeriesPlaylistApi(params);
    setPlaylistData(data?.data);
    setPlaylistCount(data?.count);
  };
  useEffect(() => {
    const getByIdFunc = async () => {
      try {
        const { data } = await getDiscoverNispandByIdApi({
          id: idval.editID,
          userRefPath,
        });
        if (data && data.data) {
          setFormData({
            title: data.data.title,
            description: data.data.description,
          });
          setObjType({
            value: data.data.objectType,
            label: data.data.objectType,
            key: data.data.objectType,
          });
          if (data.data.objectType === "MEDIA") {
            setSelectedMedia(
              data.data.metaIds.map((i) => ({
                value: i._id,
                label: i.title,
                key: i._id,
              }))
            );
          } else if (data.data.objectType === "PLAYLIST") {
            setSelectedPlaylist(
              data.data.metaIds.map((i) => ({
                value: i._id,
                label: i.title,
                key: i._id, 
              }))
            );
          } else if (data.data.objectType === "SERIES") {
            setSelectedSeries(
              data.data.metaIds.map((i) => ({
                value: i._id,
                label: i.title,
                key: i._id, 
              }))
            );
          }
          setOrder({
            value: data.data.order,
            label: data.data.order,
            key: data.data.order,
          });
          setCurrOrder({
            value: data.data.order,
            label: data.data.order,
            key: data.data.order,
          });
        }
      } catch (error) {
        console.log("Error fetching by ID:", error);
      }
    };

    getByIdFunc();
  }, [idval]);

  useEffect(() => {
    getAllMediaData({ page: 1, limit: mediaCount });
    getAllSeriesData({ type: "SERIES", page: 1, limit: seriesCount });
    getAllPlaylistData({ type: "PLAYLIST", page: 1, limit: playlistCount });
  }, [mediaCount, seriesCount, playlistCount]);

  const formatMultiSelectData = (data) => {
    let formatedData;
    if (Array.isArray(data)) {
      formatedData = data && data.length > 0 && data?.map(({ value }) => value);
    } else if (data?.value) {
      formatedData = data?.value;
    } else {
      formatedData = [];
    }
    return formatedData;
  };

  const handleSubmit = async () => {
    try {
      setShowUnPublishAlert(false);
      if (currOrder?.value === order?.value) {
        await updateDiscoverNispandApi(editID, {
          title: formData?.title,
          description: formData?.description,
          objectType: objType?.value,
          // order: order?.value,
          metaIds:
            objType?.value === "MEDIA"
              ? formatMultiSelectData(selectedMedia)
              : objType?.value === "SERIES"
              ? formatMultiSelectData(selectedSeries)
              : formatMultiSelectData(selectedPlaylist),
        });
      } else {
        await updateDiscoverNispandApi(editID, {
          title: formData?.title,
          description: formData?.description,
          objectType: objType?.value,
          order: order?.value,
          metaIds:
            objType?.value === "MEDIA"
              ? formatMultiSelectData(selectedMedia)
              : objType?.value === "SERIES"
              ? formatMultiSelectData(selectedSeries)
              : formatMultiSelectData(selectedPlaylist),
        });
      }

      setShowUnPublishAlert(false);
      setShowUnPublishConfirm(true);
    } catch (error) {
      setErrMsg(error.response.data.error)
      if (error.response.data.success === false){
        setModal(true)
      } else(setModal(false))
    }
  };
  console.log(currOrder,'error.response.data.error');
  return (
    <>
      <EditDiscoverNispandComp
        order={order}
        setOrder={setOrder}
        mediaData={mediaData}
        setMediaData={setMediaData}
        seriesData={seriesData}
        playlistData={playlistData}
        objType={objType}
        setSelectedMedia={setSelectedMedia}
        setSelectedPlaylist={setSelectedPlaylist}
        selectedPlaylist={selectedPlaylist}
        setSelectedSeries={setSelectedSeries}
        selectedSeries={selectedSeries}
        selectedMedia={selectedMedia}
        setObjType={setObjType}
        title={title}
        description={description}
        handleChange={handleChange}
        handleSubmit={handleSubmit}
        setShowUnPublishAlert={setShowUnPublishAlert}
        modal={modal}
        setModal={setModal}
        toggle={toggle}
        errMsg={errMsg}
        setErrMsg={setErrMsg}
      />
      {showUnPublishAlert ? (
        <SweetAlert
        title="Are you sure?"
        warning
        showCancel
        confirmBtnBsStyle="success"
        cancelBtnBsStyle="danger"
        confirmBtnText="Yes,Create it!"
        reverseButtons={true}
        onConfirm={() => {
          handleSubmit();
          setShowUnPublishAlert(false);
        }}
        onCancel={() => setShowUnPublishAlert(false)}
      ></SweetAlert>
      ) : null}

      {showUnPublishConfirm ? (
        <SweetAlert
          success
          title="Updated!"
          confirmBtnBsStyle="primary"
          onConfirm={() => {
            setShowUnPublishAlert();
            setShowUnPublishConfirm();
            window.location.reload()
          }}
        >
          Discover Nispand has been Updated
        </SweetAlert>
      ) : null}
    </>
  );
};

export default EditDiscoverNispand;
