import React, { useState, useEffect } from "react";
import { SleepSurveyListHead } from "../../SleepSessions.constants";
import AllSurveyUsersList from "../../../../../../Components/AllSurveyUsers";
import { AllSurveyData, AllSurveyDataCSV } from "../../SleepSessions.Api";
// import { getAllEnableMasterClass, csvDataMasterClass } from "../../../Constants/api";

const SleepSurveyList = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [publishedCourse, setPublishedCourse] = useState([]);
  const [publishPage, setPublishPage] = useState({
    currentPage: 1,
    totalSize: 10,
  });
  const { currentPage, totalSize } = publishPage;
  const [masterId, setMasterId] = useState([]);
  const [csvData, setCsvData] = useState([])

  const getSurveyList = async (params) => {
    try {
      setIsLoading(true);
      let payload = params
      if (startDate) {
        const startOfDay = startDate; // Create a new Date object with the current date and time
        startOfDay.setHours(0, 0, 0, 1);
        const isoStringStart = startOfDay.toISOString();
        payload['startDate'] = formatDate(isoStringStart)
      }
      else {//if there is no date
        const startOfDay = new Date(); // Create a new Date object with the current date and time
        startOfDay.setHours(0, 0, 0, 1);
        const isoStringStart = startOfDay.toISOString();
        payload['startDate'] = isoStringStart
      }
      if (endDate) {
        const endOfDay = endDate; // Create a new Date object with the current date and time
        endOfDay.setHours(23, 59, 59, 999);
        const isoStringEnd = endOfDay.toISOString();
        payload['endDate'] = isoStringEnd
      }
      else {//if there is no date
        const endOfDay = new Date(); // Create a new Date object with the current date and time
        endOfDay.setHours(23, 59, 59, 999);
        const isoStringEnd = endOfDay.toISOString();
        payload['endDate'] = isoStringEnd
      }

      const { data } = await AllSurveyData(params)//getAllEnableMasterClass(params);

      setPublishedCourse(data?.data);
      setPublishPage({
        currentPage: params?.page,
        totalSize: data?.count,
      });
      // if (data?.allRegisteredMasterClass?.length > 0) {
      //   setMasterId(data.allRegisteredMasterClass[0]._id);
      // }

      setIsLoading(false);
    } catch (error) {
      console.log(error);
      setIsLoading(false);
    }
  };
  // const extractAndSetMasterIds = (courses) => {
  //   if (courses?.length > 0) {
  //     const ids = courses.map((course) => course._id);
  //     setMasterId(ids);
  //   }
  // };

  const handleTablePageChange = (type, { page, sizePerPage }) => {
    getSurveyList({ page, limit: sizePerPage });
    setPublishPage({
      ...publishPage,
      currentPage: page,
    });
  };

  const handleEditSeries = (id) => {
    history.push("/dashboard/master-user/" + id);
  };

  const getAllUsersDataCsv = async (params) => {
    try {

      setIsLoading(true)
      let payload = {}
      if (startDate) {
        const startOfDay = startDate; // Create a new Date object with the current date and time
        startOfDay.setHours(0, 0, 0, 1);
        const isoStringStart = startOfDay.toISOString();
        payload['startDate'] = formatDate(isoStringStart)
      }
      else {//if there is no date
        const startOfDay = new Date(); // Create a new Date object with the current date and time
        startOfDay.setHours(0, 0, 0, 1);
        const isoStringStart = startOfDay.toISOString();
        payload['startDate'] = isoStringStart
      }
      if (endDate) {
        const endOfDay = endDate; // Create a new Date object with the current date and time
        endOfDay.setHours(23, 59, 59, 999);
        const isoStringEnd = endOfDay.toISOString();
        payload['endDate'] = isoStringEnd
      }
      else {//if there is no date
        const endOfDay = new Date(); // Create a new Date object with the current date and time
        endOfDay.setHours(23, 59, 59, 999);
        const isoStringEnd = endOfDay.toISOString();
        payload['endDate'] = isoStringEnd
      }
      const { data } = await AllSurveyDataCSV(payload)
      setCsvData(data?.data)
      // const { data } = await csvDataMasterClass(`startDate=${payload['startDate']}&endDate=${payload['endDate']}`)//getAllEnableMasterClass(params);
      // setCsvData(data?.allRegisteredMasterClass)
      // setPublishedCourse(data?.allRegisteredMasterClass);
      // // setPublishPage({
      // //   currentPage: params?.page,
      // //   totalSize: data?.count,
      // // });
      // if (data?.allRegisteredMasterClass?.length > 0) {
      //   setMasterId(data.allRegisteredMasterClass[0]._id);
      // }

      setIsLoading(false)
    } catch (error) {
      setIsLoading(false)
      console.error('Error fetching or processing data:', error);
    }
  };
  console.log(csvData, 'printData')

  const handleAllExportToCSV = () => {
    const csvDataAll = csvData?.map((item) => ({
      Name: item?.userId?.firstName,
      Email: item?.userId?.email,
      'Phone Number': item?.userId?.phoneNumber,
      Goal: item?.goal,
      CreatedAt: item?.createdAt ? new Date(item.createdAt).toISOString().substring(0, 10) : ''
      // UserId: item?.userId?._id,
      // UserFirstName: item?.userId?.firstName,
      // UserLastName: item?.userId?.lastName,
      // UserPhoneNumber: item?.userId?.phoneNumber,
      // UserEmail: item?.userId?.email,
      // UserAge: item?.userId?.age,
      // UserCountry: item?.userId?.country,
      // UserDOB: item?.userId?.dob ? new Date(item.userId.dob).toISOString().substring(0, 10) : '',
      // UserGender: item?.userId?.gender,
      // UserHeight: item?.userId?.height,
      // UserMaritalStatus: item?.userId?.maritalStatus,
      // UserProfileUrl: item?.userId?.profileUrl,
      // UserWeight: item?.userId?.weight,
      // UserFirebaseToken: item?.userId?.firebaseToken,
      // UserSubscriptionRenewed: item?.userId?.isSubscriptionRenewed,
      // UserLastActiveDate: item?.userId?.lastActiveDate ? new Date(item.userId.lastActiveDate).toISOString().substring(0, 10) : '',
      // UserSubscriptionsId: item?.userId?.subscriptionsId,
      // UserLangPref: item?.userId?.langPref,
      // UserCountryCode: item?.userId?.countryCode,
      // UserReferralCode: item?.userId?.referralCode,
      // MasterclassId: item?.masterclassId?._id,
      // MasterclassTitle: item?.masterclassId?.title,
      // // MasterclassDescription: item?.masterclassId?.description,
      // MasterclassImage: item?.masterclassId?.image,
      // MasterclassTargetDate: item?.masterclassId?.targetDate ? new Date(item.masterclassId.targetDate).toISOString().substring(0, 10) : '',
      // MasterclassJoinLink: item?.masterclassId?.joinLink,
      // MasterclassTagline: item?.masterclassId?.tagline,
      // MasterclassSupportAdvisorId: item?.masterclassId?.supportAdvisorId,
      // MasterclassIsPublished: item?.masterclassId?.isPublished,
      // MasterclassIsFree: item?.masterclassId?.isFree,
      // MasterclassCreatedAt: item?.masterclassId?.createdAt ? new Date(item.masterclassId.createdAt).toISOString().substring(0, 10) : '',
      // MasterclassUpdatedAt: item?.masterclassId?.updatedAt ? new Date(item.masterclassId.updatedAt).toISOString().substring(0, 10) : '',
      // IsBooked: item?.isBooked,
      // IsFree: item?.isFree,
      // Title: item?.masterclassId?.title,
      // 'Tag Line': item?.masterclassId?.tagline,
      // CreatedAt: item?.createdAt ? new Date(item.createdAt).toISOString().substring(0, 10) : '',
      // 'Join Link': item?.masterclassId?.joinLink
      // UpdatedAt: item?.updatedAt ? new Date(item.updatedAt).toISOString().substring(0, 10) : ''
    }));

    return csvDataAll;
  };
  const formatDate = (date) => {
    const iso8601Regex = /^(\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?Z?)$/;
    // Test the input string against the regular expression
    if (iso8601Regex.test(date)) {
      return date
    }
    else {
      return date.toISOString()//.slice(0, 10)}
    }
  }

  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()
  const handleStartDateChange = (date) => {
    setStartDate(date)
  }
  const handleEndDateChange = (date) => {
    setEndDate(date)
  }
  const handleConfirmClick = async () => {
    getSurveyList({ page: 1, limit: 10 });
    getAllUsersDataCsv()
  }


  useEffect(() => {
    setStartDate(new Date())
    setEndDate(new Date())
    getSurveyList({ page: 1, limit: 10 });
    getAllUsersDataCsv()
  }, []);

  // console.log(masterId, "dedo naa");

  return (
    <>
      <AllSurveyUsersList
        tableData={publishedCourse}
        tableHeading={SleepSurveyListHead}
        isLoading={isLoading}
        page={currentPage}
        sizePerPage={10}
        totalSize={totalSize}
        // handleEditSeries={handleEditSeries}
        masterId={masterId}
        handleAllExportToCSV={handleAllExportToCSV}
        startDate={startDate}
        endDate={endDate}
        handleEndDateChange={handleEndDateChange}
        handleStartDateChange={handleStartDateChange}
        handleConfirmClick={handleConfirmClick}
        handleTablePageChange={handleTablePageChange}
      />
    </>
  );
};

export default SleepSurveyList;
